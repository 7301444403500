import React, { useEffect } from 'react';
import { faHourglassHalf, faTimes } from '@fortawesome/free-solid-svg-icons';

import Section from '@heureca/shared/components/Section';
import Card from '@heureca/signUp/models/Card.model';
import Illustration from '@assets/signUp/index';
import useSignUp from '@heureca/signUp/hooks/useSignUp.hook';
import useTranslations from '@heureca/shared/hooks/useTranslations.hook';
import Layout from '@heureca/shared/components/Layout';

import EndCardContainer from '../EndCardContainer';

import * as styles from './error.module.scss';

const errorCards: Card[] = [
  {
    type: 'failed',
    image: Illustration.Fail,
    title: 'Inscription',
    description: 'Vous créez votre compte Heureca',
    icon: faTimes,
  },
  {
    type: 'success',
    image: Illustration.Check,
    title: 'Vérification',
    description: 'Nous vérifions vos informations',
    icon: faHourglassHalf,
  },
  {
    type: 'waiting',
    image: Illustration.Contact,
    title: 'Confirmation',
    description: "Nous prenons contact avec vous pour finaliser l'inscription",
  },
];

const Error = () => {
  const { reset } = useSignUp();
  const { translate } = useTranslations();

  useEffect(() => {
    reset();
  }, []);

  return (
    <Layout pageTitle={translate('signUp.title')} isSignUp>
      <Section
        name="Erreur"
        title={translate('steps.error.title')}
        containerClassNames={styles.content}
      >
        <EndCardContainer
          title="Vos informations semblent incorrectes ou sont déjà existantes dans notre système."
          cards={errorCards}
        />

        <div className={styles.bottomExplanation}>
          <p>
            Il existe différentes raisons qui peuvent
            bloquer la création de votre site de commande :
          </p>
          <ul className={styles.list}>
            <li>
              Vous êtes déjà inscrit sur notre plateforme.
            </li>
            <li>
              Vous n&apos;avez pas introduit les bonnes informations.
            </li>
          </ul>
          <p>
            Si les informations que vous avez entrées sont correctes,
            notre équipe prendra contact avec vous afin de finaliser votre inscription.
          </p>
          <p>
            Dans le cas où vous avez déjà obtenu un compte Heureca, vous n&apos;êtes plus dans
            les conditions pour bénéficier des 30 jours d&apos;essais gratuits, notre équipe prendra
            contact avec vous pour mettre en place un nouveau compte.
          </p>
        </div>
      </Section>
    </Layout>
  );
};

export default Error;
